<template>
  <h1>{{ displayText }}</h1>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
export default {
  data() {
    return {
      displayText: "",
    };
  },
  mounted() {
    this.getDisplayText();
  },
  methods: {
    getDisplayText() {
      axios
        .get(`${constants.SERVER_API}getEntryTexts`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.displayText = response.data.message;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
